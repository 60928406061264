import { render, staticRenderFns } from "./category-card.vue?vue&type=template&id=17ca5a36&scoped=true&"
import script from "./category-card.vue?vue&type=script&lang=js&"
export * from "./category-card.vue?vue&type=script&lang=js&"
import style0 from "./category-card.vue?vue&type=style&index=0&id=17ca5a36&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17ca5a36",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VCard,VCardTitle,VChip,VImg})


/* hot reload */
if (module.hot) {
  var api = require("/opt/jenkins/workspace/starling-app-staging-ci/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('17ca5a36')) {
      api.createRecord('17ca5a36', component.options)
    } else {
      api.reload('17ca5a36', component.options)
    }
    module.hot.accept("./category-card.vue?vue&type=template&id=17ca5a36&scoped=true&", function () {
      api.rerender('17ca5a36', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/content/components/category-card.vue"
export default component.exports