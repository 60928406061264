import { render, staticRenderFns } from "./video-card.vue?vue&type=template&id=66099a7b&scoped=true&"
import script from "./video-card.vue?vue&type=script&lang=js&"
export * from "./video-card.vue?vue&type=script&lang=js&"
import style0 from "./video-card.vue?vue&type=style&index=0&id=66099a7b&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66099a7b",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VCard,VCardActions,VImg})


/* hot reload */
if (module.hot) {
  var api = require("/opt/jenkins/workspace/starling-app-staging-ci/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('66099a7b')) {
      api.createRecord('66099a7b', component.options)
    } else {
      api.reload('66099a7b', component.options)
    }
    module.hot.accept("./video-card.vue?vue&type=template&id=66099a7b&scoped=true&", function () {
      api.rerender('66099a7b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/content/components/video-card.vue"
export default component.exports